import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const initDefault = {
  menubar: false,
  plugins: 'link lists table',
  toolbar:
    'undo redo | styleselect | fontselect | fontsizeselect bold italic underline strikethrough forecolor backcolor | subscript superscript | link unlink | alignleft aligncenter alignright alignjustify | lineheight | numlist bullist indent outdent | table | blockquote | removeformat |',
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: ({ disabled }) =>
      disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  },
}));

const RichTextEditor = ({ editorInit, label, disabled, ...restProps }) => {
  const classes = useStyles({ disabled });

  const init = { ...initDefault, ...editorInit };

  return (
    <div>
      {label && (
        <Typography className={classes.label} gutterBottom>
          {label}
        </Typography>
      )}
      <Editor init={init} {...restProps} />
    </div>
  );
};

RichTextEditor.defaultProps = {
  editorInit: {},
  disabled: false,
};

RichTextEditor.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  editorInit: PropTypes.object,
};

export default RichTextEditor;
