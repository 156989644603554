import React, { useRef, useState } from 'react';
import { useField } from 'formik';

import RichTextEditor from '../RichTextEditor';

const RichTextField = (props) => {
  const editorRef = useRef();

  const [{ value }, , { setValue }] = useField(props);

  // NOTE editor is initialized with an initial value and then manages its own
  // state for performance reasons. if we change the initial value, the editor
  // resets itself, so we shouldn't do that.
  const [initialValue] = useState(value);

  const handleEditorInit = (_, editor) => (editorRef.current = editor);

  // NOTE for performance reasons, we use editor as an uncontrolled input. we
  // read its value in a blur event.
  const handleBlur = () => setValue(editorRef.current.getContent());

  return (
    <RichTextEditor
      initialValue={initialValue}
      onInit={handleEditorInit}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default RichTextField;
